import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Button, Divider, Input, Tooltip } from 'antd';
import Proptypes from 'prop-types';

import { generateFileUrl } from '../../utilities/Functions';

import CustomIcon from '../CustomIcon/CustomIcon';
import HeadTable from '../HeadTable/HeadTable';
import Select from '../Select/Select';

import './index.less';

const { TextArea } = Input;
const { Title } = Typography;
const NewActionModal = ({ file, onCancel, onAddAction, tracking, fileName, listResponsible }) => {
  const [files, setFiles] = useState([]);

  const [currentTracking, setCurrentTracking] = useState({});
  const [disableBn, setDisableBn] = useState(true);

  const onDisableBn = () => {
    if (
      currentTracking.Responsible === null ||
      currentTracking.Action === null ||
      currentTracking.Subject === ''
    ) {
      setDisableBn(true);
    } else {
      setDisableBn(false);
    }
  };
  const headNewAction = [
    {
      title: 'Responsable - área',
      width: 3,
    },
    {
      title: 'Acción',
      width: 3,
    },
    {
      title: 'Asunto',
      width: 10,
    },
    {
      title: 'Adjuntos',
      width: 3,
    },
    {
      title: 'Comentarios',
      width: 5,
    },
  ];

  const onChange = async (e) => {
    try {
      const filesSave = e.target?.files[0];
      const response = await generateFileUrl('tracking', filesSave);
      if (filesSave) {
        const currentFile = {
          ...filesSave,
          ...response,
        };
        setFiles([...files, currentFile]);
      }
    } catch (error) {
      // Manejar el error aquí adecuadamente
    }
  };
  const onChangeAction = (name, value) => {
    let newTracking = { ...currentTracking, [name]: value };

    if (name === 'Action') {
      if (value === 'Finalizar') {
        newTracking = { ...newTracking, Order: 4, Name: 'Finalizado' };
      } else {
        newTracking = { ...newTracking, Order: 3, Name: 'En Proceso' };
      }
    }
    setCurrentTracking(newTracking);
  };

  const removeFile = (id) => {
    const customFiles = [...files];
    const itemsFilter = customFiles.filter((filtFile) => filtFile.id !== id);
    setFiles([...itemsFilter]);
  };

  useEffect(() => {
    if (tracking.Clear) {
      setCurrentTracking(tracking);
      setFiles([]);
    }
  }, [tracking]);

  useEffect(() => {
    onDisableBn();
  }, [currentTracking]);

  return (
    <div>
      <CustomIcon className="cancel-newActionModal" type="cancel" onClick={onCancel} />
      <div className="container-newActionModal">
        <Title className="title-newActionModal">Nueva acción para el caso : {fileName}</Title>
        <HeadTable head={headNewAction} />
        <Row key={file.date} className="row-contentNewAction">
          <Col span={3} className="column-contentNewAction">
            <Select
              placeholder="Seleccionar"
              options={listResponsible}
              value={currentTracking.Responsible}
              name="Responsible"
              onChange={(e) => onChangeAction('Responsible', e)}
            />
          </Col>
          <Col span={3} className="column-contentNewAction">
            <Select
              placeholder="Seleccionar"
              options={[
                { label: 'Revisión', value: 'Revisión' },
                { label: 'Requerimiento', value: 'Requerimiento' },
                { label: 'Finalizar', value: 'Finalizar' },
              ]}
              value={currentTracking.Action}
              name="Action"
              onChange={(e) => onChangeAction('Action', e)}
            />
          </Col>
          <Col span={10} className="column-contentNewAction">
            {/* {file.fileName} */}
            <Input
              placeholder="Ingresar asunto"
              className="input-comments"
              style={{ height: '38px' }}
              name="Subject"
              onChange={(e) => onChangeAction(e.target.name, e.target.value)}
              value={currentTracking.Subject}
            />
          </Col>
          <Col span={3} className="column-contentNewAction image-upload">
            <label htmlFor="file-input">
              <span> Ingresar</span>
              <CustomIcon type="clip" />
            </label>
            <input type="file" id="file-input" onChange={onChange} />
          </Col>
          <Col span={5}>
            <div className="comments-contentNewAction">
              <TextArea
                rows={4}
                placeholder="Ingresar comentarios"
                className="input-comments"
                onChange={(e) => onChangeAction(e.target.name, e.target.value)}
                name="Comments"
                value={currentTracking.Comments}
              />
              <Divider className="divider-contentNewAction" />
            </div>
          </Col>
        </Row>
        <div className="container-file">
          {files?.map((item) => {
            return (
              <div className="row-file" key={item.id}>
                <Tooltip placement="bottom" title="Eliminar">
                  <div className="remove-file" onClick={() => removeFile(item.id)}>
                    <CustomIcon type="trash" />
                  </div>
                </Tooltip>
                <label>{item?.filename}</label>
              </div>
            );
          })}
        </div>
        <div className="container-bn-newActionModal">
          <Button
            className="bn-newActionModal"
            type="primary"
            onClick={() => onAddAction(true, files, currentTracking)}
            disabled={disableBn}
          >
            Guardar acción
          </Button>
        </div>
      </div>
    </div>
  );
};

NewActionModal.displayName = 'NewActionModal';

NewActionModal.defaultProps = {
  file: {},
  onCancel: () => {},
};

NewActionModal.propTypes = {
  file: Proptypes.object,
  onCancel: Proptypes.func,
};
export default NewActionModal;
